"use client";
import BigNumber from "bignumber.js";
import { AreaSeriesOptions, IChartApi, UTCTimestamp, createChart } from "lightweight-charts";
import { useEffect, useRef } from "react";
import { formatNumber } from "../number/FormattedNumber";

interface AreaChartProps {
    data: { time: UTCTimestamp; value: number }[];
    className?: string;
    hidePriceScale?: boolean;
    seriesOptions?: Partial<AreaSeriesOptions>;
    chartOptions?: Partial<Parameters<typeof createChart>[1]>;
}

const cssVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name);

export function BlueLineChart({ data, className, hidePriceScale, seriesOptions, chartOptions }: AreaChartProps) {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const areaSeriesRef = useRef<ReturnType<IChartApi["addAreaSeries"]> | null>(null);

    const priceFormatter = (price: number) => {
        if (hidePriceScale) return "*******";
        return formatNumber(
            new BigNumber(price),
            {
                abbreviateNumbers: true,
                style: "currency",
                currency: "USD",
            },
            "en-US",
        );
    };

    useEffect(() => {
        if (!chartContainerRef.current) return;
        chartRef.current = createChart(chartContainerRef.current, {
            layout: {
                background: { color: cssVar("--color-slate-blue-200") },
                textColor: "#808080",
            },
            grid: {
                vertLines: { color: "rgba(128, 128, 128, 0.2)" },
                horzLines: { color: "rgba(128, 128, 128, 0.2)" },
            },
            timeScale: {
                visible: true,
                timeVisible: true,
                secondsVisible: false,
            },
            handleScale: {
                mouseWheel: false,
                pinch: false,
                axisPressedMouseMove: false,
            },
            handleScroll: {
                mouseWheel: false,
                pressedMouseMove: false,
                horzTouchDrag: false,
                vertTouchDrag: false,
            },
            rightPriceScale: {
                borderColor: "rgba(128, 128, 128, 0.2)",
                scaleMargins: {
                    top: 0.1,
                    bottom: 0,
                },
            },
            localization: { priceFormatter },
            ...chartOptions,
        });

        const chart = chartRef.current;
        areaSeriesRef.current = chart.addAreaSeries({
            topColor: "rgba(32, 61, 108, 0.6)",
            bottomColor: "rgba(39, 122, 255, 0.1)",
            lineColor: "rgba(33, 150, 243, 1)",
            lineWidth: 1,
            priceLineVisible: false,
            lastValueVisible: false,

            ...seriesOptions,
        });

        const resizeObserver = new ResizeObserver((entries) => {
            const { width, height } = entries[0].contentRect;
            chart.resize(width, height);
            chart.timeScale().fitContent();
        });
        resizeObserver.observe(chartContainerRef.current);

        return () => {
            resizeObserver.disconnect();
            chart.remove();
        };
    }, [seriesOptions, chartOptions]);

    useEffect(() => {
        if (!chartRef.current) return;

        chartRef.current.applyOptions({
            localization: { priceFormatter },
        });
    }, [hidePriceScale]);

    useEffect(() => {
        if (!areaSeriesRef.current) return;

        areaSeriesRef.current.setData(data);
        chartRef.current?.timeScale().fitContent();
    }, [data]);

    return <div ref={chartContainerRef} className={className} />;
}
