export function Separator({
    direction,
    margin = 0,
    className,
    thickness = "1px",
}: {
    direction: "horizontal" | "vertical";
    margin?: string | number;
    className?: string;
    thickness?: string | number;
}) {
    return (
        <div
            className={className}
            style={{
                margin: direction === "horizontal" ? `${margin} 0` : `0 ${margin}`,

                height: direction === "vertical" ? "100%" : undefined,
                borderLeft: direction === "vertical" ? `${thickness} solid rgba(255, 255, 255, 0.15)` : undefined,

                width: direction === "horizontal" ? "100%" : undefined,
                borderTop: direction === "horizontal" ? `${thickness} solid rgba(255, 255, 255, 0.15)` : undefined,
            }}
        />
    );
}
