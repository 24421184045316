"use client";
import { useRouter } from "@arkham/i18n";
import { Button, Logo } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useState } from "react";

export function LoggedOutState({ className }: { className: string }) {
    const t = useTranslations("LoggedOutState");
    const router = useRouter();
    const [email, setEmail] = useState("");

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const authUrl = process.env.NEXT_PUBLIC_AUTH_URL;
        const registerPath = email ? `${authUrl}/register?email=${encodeURIComponent(email)}` : `${authUrl}/register`;
        router.push(registerPath);
    };

    return (
        <div className={`flex w-full flex-col items-center justify-between p-6 md:flex-row md:p-12 ${className}`}>
            <div className="flex items-center gap-7">
                <Logo className="h-12 w-12" of="staticIcon" />
                <div className="flex flex-col">
                    <div className="text-xl">{t("newHere")}</div>
                    <div className="font-thin">{t("letGetStarted")}</div>
                </div>
            </div>

            <div className="flex w-full md:w-auto">
                <form onSubmit={handleSubmit} className="flex w-full flex-col gap-2 md:w-auto">
                    <label htmlFor="email" className="text-lg leading-none">
                        {t("email")}
                    </label>
                    <div className="flex w-full items-center gap-4 md:w-auto">
                        <input
                            id="email"
                            className="border-white-200 bg-ash-300 placeholder:text-white-500 w-full rounded-sm border border-solid px-4 py-3 leading-none focus:ring-2 focus:ring-blue-500 focus:outline-hidden md:w-auto"
                            type="email"
                            placeholder="Enter your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Button className="flex-0 shrink-0 whitespace-nowrap" type="submit" color="blue">
                            {t("signUp")}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
