import(/* webpackMode: "eager", webpackExports: ["BalanceSection"] */ "/app/apps/exchange/app/_components/home/BalanceSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/exchange/app/_components/home/HomeMain.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["LoggedOutState"] */ "/app/apps/exchange/app/_components/home/LoggedOutBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TokensSection"] */ "/app/apps/exchange/app/_components/home/TokensSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Section"] */ "/app/apps/exchange/app/_components/layout/Section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlueLineChart"] */ "/app/apps/exchange/app/_components/ui/elements/BlueLineChart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/production/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/react-fast-marquee/dist/index.js");
