"use client";
import { useBalances, useCurrentSubaccountTotalUSDValue } from "@/app/_hooks/useBalances";
import { useSubaccount } from "@/app/_hooks/useSubaccount";
import { Link } from "@arkham/i18n";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import React, { useMemo } from "react";
import { Section } from "../layout/Section";
import { Separator } from "../layout/Separator";
import { ToggleBalanceShown } from "../ui/buttons/ToggleBalanceShown";
import { Table } from "../ui/elements/table/Table";
import { AssetIcon } from "../ui/images/AssetIcon";
import { PrivacyNumber } from "../ui/number/PrivacyNumber";

interface BalanceSectionProps {
    className?: string;
}

export function MobileBalanceHeader({ balancesTotalUSDValue }: { balancesTotalUSDValue: string }) {
    const { selectedSubaccount, isLoading } = useSubaccount();
    const t = useTranslations("BalanceSection");
    return (
        <div className="flex w-100 flex-col items-start gap-3">
            <div className="flex w-full items-center justify-between">
                <div className="text-white-800 text-xs">
                    <span>{t("account")} &nbsp;</span>
                    <span className="text-blue-700">{isLoading ? "Loading..." : selectedSubaccount?.name}</span>
                </div>
                <ToggleBalanceShown className="text-white-900" label="Hide" />
            </div>
            <div className="flex flex-col gap-1.5">
                <div className="text-white-700">{t("balance")}</div>
                <PrivacyNumber
                    className="text-2xl"
                    number={balancesTotalUSDValue}
                    options={{
                        fractionDigits: 2,
                        style: "currency",
                        currency: "USD",
                    }}
                />
            </div>
        </div>
    );
}

function DesktopHeader({ balancesTotalUSDValue, isLoading }: { balancesTotalUSDValue: string; isLoading: boolean }) {
    const t = useTranslations("BalanceSection");
    return (
        <>
            <div className="flex items-center justify-between">
                <div className="flex flex-row items-center gap-1.5">
                    <div className="text-gray-1000">{t("yourBalance")}</div>
                    <ToggleBalanceShown />
                </div>
                <Link href="/wallet/assets" className="text-xs text-blue-700">
                    {t("fullAccount")}
                </Link>
            </div>
            <div className="text-3xl">
                {isLoading ? (
                    <div className=""> -</div>
                ) : (
                    <PrivacyNumber
                        number={balancesTotalUSDValue}
                        options={{
                            fractionDigits: 2,
                            style: "currency",
                            currency: "USD",
                        }}
                    />
                )}
            </div>
        </>
    );
}

export const BalanceSection = ({ className }: BalanceSectionProps) => {
    const { balances } = useBalances();
    const topBalances = useMemo(() => {
        return balances.slice(0, 6);
    }, [balances]);
    const { balancesTotalUSDValue, isLoading } = useCurrentSubaccountTotalUSDValue();
    const t = useTranslations("BalanceSection");
    return (
        <Section className={`flex w-full flex-col justify-start gap-3 rounded-sm p-3 ${className}`}>
            <div className="md:hidden">
                <MobileBalanceHeader balancesTotalUSDValue={balancesTotalUSDValue} />
            </div>
            <div className="hidden md:block">
                <DesktopHeader balancesTotalUSDValue={balancesTotalUSDValue} isLoading={isLoading} />
            </div>

            <div className="h-64 w-full">
                <Separator direction="horizontal" />
                <Table
                    columns={[
                        { header: t("asset") },
                        { header: t("balance"), justifyContent: "justify-end" },
                        { header: "USD", justifyContent: "justify-end" },
                    ]}
                >
                    {topBalances?.map((asset) => (
                        <React.Fragment key={asset.symbol}>
                            <div className="flex items-center gap-0.5">
                                <AssetIcon className="mr-1" url={asset.imageUrl} width={14} height={14} />
                                <span>{asset.name}</span>
                                <span className="text-gray-1000">{asset.symbol}</span>
                            </div>

                            <div>
                                <PrivacyNumber
                                    number={asset.balance}
                                    options={{
                                        fractionDigits: 6,
                                    }}
                                />
                            </div>
                            <div>
                                <PrivacyNumber
                                    number={asset.balanceUSDT}
                                    options={{
                                        fractionDigits: 2,
                                        style: "currency",
                                        currency: "USD",
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </Table>
            </div>
            <div className="md:hidden">
                <Link href="/wallet/assets">
                    <Button color="blue" variant="primary" className="w-full">
                        {t("fullAccount")}
                    </Button>
                </Link>
            </div>
        </Section>
    );
};
