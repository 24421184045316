"use client";
import { countDecimalPlaces } from "@/app/_components/trade/orderform/utils";
import { MergedTicker, useTickers } from "@/app/_hooks/useFetch";
import { useWindowSize } from "@/app/_hooks/useWindowSize";
import { Tabs } from "@arkham/ui-components";
import BigNumber from "bignumber.js";
import { useTranslations } from "next-intl";
import React, { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { Separator } from "../layout/Separator";
import { Column, Table } from "../ui/elements/table/Table";
import { AssetIcon } from "../ui/images/AssetIcon";
import { FormattedNumber } from "../ui/number/FormattedNumber";
import { PriceChange } from "../ui/number/PriceChange";

type TokensSectionTab = "spot" | "perpetuals";

interface PairRowProps {
    pair: MergedTicker;
    isPerpetual?: boolean;
}

const PairRow: React.FC<PairRowProps> = ({ pair, isPerpetual = false }) => (
    <>
        <div className="flex items-center">
            <AssetIcon className="mr-1.5" url={pair.baseImageUrl} url2={pair.quoteImageUrl} width={20} height={20} />
            <span>
                {pair.baseSymbol}/{pair.quoteSymbol}
            </span>
        </div>
        <FormattedNumber
            number={pair.price}
            options={{
                style: "currency",
                currency: "USD",
                fractionDigits: countDecimalPlaces(pair.price),
                abbreviateNumbers: false,
            }}
        />
        <PriceChange price={pair.price} price24hAgo={pair.price24hAgo} />

        <FormattedNumber
            number={pair.quoteVolume24h}
            className="hidden md:block"
            options={{
                style: "currency",
                currency: "USD",
                fractionDigits: 2,
                abbreviateNumbers: false,
            }}
        />
    </>
);

const sortByVolume = (a: MergedTicker, b: MergedTicker) =>
    new BigNumber(b.usdVolume24h).minus(a.usdVolume24h).toNumber();

export function TokensSection() {
    const t = useTranslations("TokensSection");
    const [tab, setTab] = useState<TokensSectionTab>("spot");
    const [searchQuery, setSearchQuery] = useState("");
    const { breakpointAbove, breakpointBelow, breakpoint } = useWindowSize();

    const getColumns = () => {
        const columns = [
            { header: t("asset"), justifyContent: "justify-start" },
            { header: t("price"), justifyContent: "justify-end" },
            { header: t("24hChange"), justifyContent: "justify-end" },
            { header: t("24hVolume"), justifyContent: "justify-end", breakpoint: "md" },
        ] as Column[];

        return columns;
    };

    const { tickers } = useTickers();

    const lowerSearchQuery = searchQuery.toLowerCase();

    const { spot, perpetual } = tickers.reduce(
        (acc, item) => {
            if (item.symbol.toLowerCase().includes(lowerSearchQuery)) {
                if (item.pairType === "spot") {
                    acc.spot.push(item);
                } else if (item.pairType === "perpetual") {
                    acc.perpetual.push(item);
                }
            }
            return acc;
        },
        { spot: [], perpetual: [] } as { spot: MergedTicker[]; perpetual: MergedTicker[] },
    );

    const spotPairs = spot.sort(sortByVolume);
    const perpetualPairs = perpetual.sort(sortByVolume);

    const [isFullscreenSearch, setIsFullscreenSearch] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isFullscreenSearch && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isFullscreenSearch]);

    useEffect(() => {
        if (breakpointAbove("md") && isFullscreenSearch) {
            setIsFullscreenSearch(false);
            setSearchQuery("");
        }
    }, [breakpoint, isFullscreenSearch]);

    const handleSearchFocus = () => {
        if (breakpointBelow("md")) {
            setIsFullscreenSearch(true);
        }
    };

    const handleCloseFullscreenSearch = () => {
        setIsFullscreenSearch(false);
        setSearchQuery("");
    };

    const renderSearchInput = () => (
        <div className="relative flex items-center">
            <BiSearch className="text-white-800 pointer-events-none absolute left-2 md:text-xs" />
            <input
                ref={searchInputRef}
                className="bg-slate-blue-700 placeholder:text-white-800 md:bg-white-100 w-full rounded-lg p-1 pl-8 focus:outline-hidden md:mt-0 md:rounded-sm md:pl-6 md:text-xs"
                type="text"
                placeholder={t("searchPlaceholder")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={handleSearchFocus}
            />
        </div>
    );

    const renderTabs = () => (
        <Tabs
            tabs={[
                { id: "spot", name: t("spot") },
                { id: "perpetuals", name: t("perpetuals") },
            ]}
            tab={tab}
            showBorder={false}
            setTab={setTab}
            widthClasses="w-full"
            className="w-full md:w-auto"
            textAlignClasses="text-center"
        />
    );

    const renderContent = (isFullscreen: boolean) => {
        const pairs = tab === "spot" ? spotPairs : perpetualPairs;
        const displayPairs = isFullscreen ? pairs : pairs.slice(0, 5);

        return (
            <Table
                columns={getColumns()}
                rowHeight={"3rem"}
                rowLink={(i) => `/trade/${displayPairs[i].symbol}`}
                sidePadding="0.75rem"
                onEmpty={t("noPairsFound")}
            >
                {displayPairs.map((pair) => (
                    <PairRow key={pair.symbol} pair={pair} isPerpetual={tab === "perpetuals"} />
                ))}
            </Table>
        );
    };

    if (isFullscreenSearch) {
        return (
            <div className="bg-slate-blue-200 fixed top-0 left-0 z-50 flex h-screen w-screen flex-col p-3">
                <div className="flex flex-col gap-2">
                    {renderTabs()}
                    {renderSearchInput()}
                </div>
                <button
                    className="text-white-800 absolute top-3 right-3 cursor-pointer border-none bg-none"
                    onClick={handleCloseFullscreenSearch}
                >
                    <CgClose />
                </button>
                <div className="grow overflow-y-auto">{renderContent(true)}</div>
            </div>
        );
    }

    return (
        <div className="flex-1">
            <div className="flex w-full flex-col gap-3 md:flex-row md:items-start md:justify-between">
                {renderTabs()}
                {renderSearchInput()}
            </div>

            <Separator direction="horizontal" className="hidden md:block" />
            {renderContent(false)}
        </div>
    );
}
